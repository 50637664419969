import React from 'react'
import './contacto.scss'
import emailjs from "emailjs-com";

const Contacto = ({ propRef }) => {
    const ciudades = [ "Abasolo", "Acuña", "Allende", "Arteaga", "Candela", "Castaños", "Cuatro Ciénegas", "Escobedo", "Francisco I. Madero", "Frontera", "General Cepeda", "Guerrero", "Hidalgo", "Jiménez", "Juárez", "Lamadrid", "Matamoros", "Monclova", "Morelos", "Múzquiz", "Nadadores", "Nava", "Ocampo", "Parras", "Piedras Negras", "Progreso", "Ramos Arizpe", "Sabinas", "Sacramento", "Saltillo", "San Buenaventura", "San Juan de Sabinas", "San Pedro", "Sierra Mojada", "Torreón", "Viesca", "Villa Unión", "Zaragoza" ]

    function sendEmail(e) {
        e.preventDefault();
        emailjs
            .sendForm(
                "MarceloTorres",
                "template_ke6fdin",
                e.target,
                "user_fPoZvSTbi1Q7kpK419fnJ"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    }
    return (
        <div className="contacto-container" ref={propRef}>
            <div className="contacto-formulario">
                <div className="⼓">
                    <h3>CONTÁCTAME</h3>
                    <h4>¿Algo que me quieras decir?</h4>
                    <p>Estoy aquí para escucharte</p>
                    <form onSubmit={sendEmail}>
                        <input type="text" placeholder="Nombre" name="nombre"/>
                            <div>
                                <input type="tel" placeholder="Celular" name="cel"/>
                                <div className="select-wrapper">
                                <select name="ciudad">
                                    <option disabled selected>Ciudad</option>
                                    { ciudades.map(ciudad => <option value={ciudad}>{ciudad}</option> )}
                                </select>
                                </div>
                            </div>
                        <textarea name="mensaje" cols="30" rows="10" placeholder="Mensaje"></textarea>
                        <button type="submit">Enviar</button>
                    </form>
                </div>
            </div>
            <div className="contacto-info">
                <div className="contacto-datos">
                    <h3>DATOS DE CONTACTO</h3>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.1053 15.8021C20.6248 15.8021 19.1748 15.571 17.7992 15.1162C17.1284 14.8851 16.368 15.0632 15.9259 15.5129L13.2013 17.5706C10.0762 15.9032 8.07537 13.9036 6.42826 10.7988L8.43034 8.13855C8.93434 7.63331 9.11499 6.89685 8.89899 6.20592C8.44295 4.824 8.21055 3.37263 8.21055 1.89474C8.21055 0.850086 7.36047 0 6.31581 0H1.89474C0.850086 0 0 0.850086 0 1.89474C0 14.0829 9.91706 24 22.1053 24C23.1499 24 24 23.1499 24 22.1053V17.6969C24 16.6522 23.1499 15.8021 22.1053 15.8021ZM22.7369 22.1053C22.7369 22.4539 22.4527 22.7369 22.1053 22.7369C10.613 22.7369 1.26314 13.387 1.26314 1.89474C1.26314 1.54611 1.54735 1.26314 1.89474 1.26314H6.31581C6.6632 1.26314 6.94741 1.54611 6.94741 1.89474C6.94741 3.50781 7.20006 5.09181 7.69518 6.59369C7.76843 6.82988 7.7091 7.07371 7.47794 7.31369L5.17897 10.3579C5.03373 10.5511 5.01099 10.8101 5.12089 11.0248C6.99413 14.7044 9.26912 16.9806 12.9752 18.8792C13.1887 18.9903 13.4502 18.9676 13.6434 18.8211L16.7533 16.464C16.9226 16.2973 17.1714 16.2392 17.3963 16.3137C18.9071 16.8127 20.4911 17.0653 22.1054 17.0653C22.4528 17.0653 22.737 17.3482 22.737 17.6969V22.1053H22.7369Z" fill="white"/>
                        </svg>
                        <a href="tel:8442421100">(844) 242 1100</a>
                    </div>
                    <div>
                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 2.97684V2.59813C24 1.17223 22.8399 0.012207 21.4141 0.012207H2.58593C1.16002 0.012207 0 1.17223 0 2.59813V2.97684V2.97961V15.4013C0 16.8272 1.16002 17.9871 2.58593 17.9871H21.4142C22.84 17.9871 24 16.8272 24 15.4013V2.97961C24 2.97871 24 2.97778 24 2.97684ZM2.58593 1.41847H21.4141C22.0645 1.41847 22.5937 1.94764 22.5937 2.59813V2.59846L13.1767 8.87648C12.5498 9.29437 11.4502 9.29433 10.8235 8.87653L1.40626 2.59841V2.59809C1.40626 1.94764 1.93544 1.41847 2.58593 1.41847ZM21.4142 16.5809H2.58593C1.93544 16.5809 1.40626 16.0517 1.40626 15.4013V4.28851L10.0435 10.0466C10.5921 10.4124 11.2959 10.5952 12 10.5952C12.704 10.5952 13.4081 10.4123 13.9567 10.0466L22.5938 4.28851V15.4013C22.5937 16.0517 22.0646 16.5809 21.4142 16.5809Z" fill="white"/>
                        </svg>
                        <a href="mailto:contacto@marcelotorres.mx">contacto@marcelotorres.mx</a>
                    </div>
                    <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.0094 3.99792 11.5434 4.82198 12.8743L11.5197 23.6676C11.648 23.8744 11.874 24 12.1171 24C12.119 24 12.1208 24 12.1227 24C12.3679 23.9981 12.5944 23.8686 12.7204 23.6582L19.2474 12.7603C20.026 11.4576 20.4375 9.96277 20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0ZM18.0406 12.0383L12.1065 21.9462L6.0172 12.1334C5.33128 11.0257 4.95938 9.74766 4.95938 8.4375C4.95938 4.56047 8.12297 1.39687 12 1.39687C15.877 1.39687 19.0359 4.56047 19.0359 8.4375C19.0359 9.7088 18.6885 10.9541 18.0406 12.0383Z" fill="white"/>
                        <path d="M12 4.21875C9.67378 4.21875 7.78125 6.11128 7.78125 8.4375C7.78125 10.7489 9.64298 12.6562 12 12.6562C14.3861 12.6562 16.2188 10.7235 16.2188 8.4375C16.2188 6.11128 14.3262 4.21875 12 4.21875ZM12 11.2594C10.4411 11.2594 9.17813 9.9922 9.17813 8.4375C9.17813 6.88669 10.4492 5.61563 12 5.61563C13.5508 5.61563 14.8172 6.88669 14.8172 8.4375C14.8172 9.96952 13.5836 11.2594 12 11.2594Z" fill="white"/>
                    </svg>
                    <p>Calle Comonfort 344, Segundo de Cobián Centro, 27000 Torreón, Coah.</p>
                    </div>
                </div>
                <div className="contacto-mapa">
                    <iframe title="Mapa de ubicacion" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.0176200568676!2d-103.4448311849836!3d25.537789983738904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fdbddaa5d347d%3A0xc85f8dc74957a19e!2sCalle%20Comonfort%20344%2C%20Segundo%20de%20Cobi%C3%A1n%20Centro%2C%2027000%20Torre%C3%B3n%2C%20Coah.!5e0!3m2!1ses-419!2smx!4v1618034784818!5m2!1ses-419!2smx" style={{ border:0, aspectRatio: 16/9 }} allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>            
        </div>
    )
}

export default Contacto
