import React from "react";
import "./trayectoria.scss";
import linevertical from "./../../assets/img/linevertical.svg";
import linehorizontal from "../../assets/img/linehorizontal.svg";

const Trayectoria = ({ propRef }) => {
  return (
    <div className="trayectoria-container" ref={ propRef }>
      <h4>MI TRAYECTORIA Y MIS OBJETIVOS</h4>
      <img
        className="linevertical"
        src={linevertical}
        alt={"Trayectoria de Marcelo Torres"}
      />
      <div className="grid-years">
        <div className="section">
          {" "}
          <h3>2010</h3>
          <p>Inicio de carrera política. Regidor del Ayuntamiento de Torreón</p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2012</h3>
          <p>Diputado Federal del estado de Coahuila en la LXII Legislatura</p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2015</h3>
          <p>Coordinador del PAN en la Cámara de Diputados</p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2017</h3>
          <p>Diputado local del 8vo Distrito por Coahuila</p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2018</h3>
          <p>Secretario y Presidente Nacional del PAN.</p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2019</h3>
          <p>
            Presidente de la Junta de Gobierno del LXI Legislatura del Congreso
            del Estado de Coahuila
          </p>
          <img
            className="linehorizontal"
            src={linehorizontal}
            alt={"Trayectoria de Marcelo Torres"}
          />
        </div>
        <div className="section">
          {" "}
          <h3>2020</h3>
          <p>Presidente de la Mesa Directiva del Congreso de Coahuila</p>
        </div>
      </div>
    </div>
  );
};

export default Trayectoria;
