import React from 'react'
import './propuestas.scss'
import Marcelo2 from './../../assets/img/Marcelo2.svg'
import Mas from './../../assets/img/mas.svg'
import Arrow from './../../assets/img/arrow.svg'
import one from './../../assets/img/one.png'
import two from './../../assets/img/two.png'
import three from './../../assets/img/three.png'
import four from './../../assets/img/four.png'
import pdf from './../../assets/docs/Plan-Municipal-de-Desarrollo-2022-2024-MARCELO-TORRES.pdf'

const Propuestas = ({ propRef }) => {

    const plan = () => {
        window.open(pdf, "_blank")
    }
    
    return (
        <main>
            <div className="propuestas-header">
                <img className="propuestas-marcelo-wave" src={Marcelo2} alt="Marcelo Torres"/>
                <p>PROPUESTAS <br/> DE CAMPAÑA</p>
                <img className="propuestas-mas" src={Mas} alt=""/>
                <button onClick={plan}>
                    Conoce mis propuestas
                    <img src={Arrow} alt=""/>
                </button>
            </div>
            <div className="propuestas-propuestas"> 
                <div className="propuestas-propuesta" ref={ propRef }>
                    <div className="propuesta-imagen">
                        <img src={one} alt=""/>
                        <div className="propuesta-imagen-filtro"></div>
                        <div className="propuesta-indice">1</div>
                        <p className="propuesta-titulo">
                            <span>Créditos</span> a la palabra <span>para mujeres</span>
                        </p>
                    </div>
                    <div className="propuesta-datos">
                        <div className="propuesta-datos-cabecera">
                            <h3>OBJETIVO ESPECÍFICO</h3>
                            <p>Reducir la brecha de ingresos entre hombres y mujeres</p>
                        </div>
                        <div className="propuesta-datos-contenido">
                            <h3>LÍNEAS DE ACCIÓN</h3>
                            <ul>
                                <li>Otorgar créditos a la palabra a grupos solidarios de entre 10-15 mujeres de MXN $6,000 – $30,000</li>
                                <li>Ofrecer capacitación, asesoría y formación a mujeres para impulsar su emprendimiento</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="propuestas-propuesta">
                    <div className="propuesta-imagen">
                        <img src={two} alt=""/>
                        <div className="propuesta-imagen-filtro"></div>
                        <div className="propuesta-indice">2</div>
                        <p className="propuesta-titulo">
                        Centro logístico <span>industrial 4.0</span>
                        </p>
                    </div>
                    <div className="propuesta-datos">
                        <div className="propuesta-datos-cabecera">
                            <h3>OBJETIVO ESPECÍFICO</h3>
                            <p>Aumentar la oferta de hospedaje industrial para inversionistas nacionales e internacionales a través de asociaciones público-privadas que promuevan la construcción o desarrollo de naves industriales, modelos legales que involucren incentivos fiscales.</p>
                        </div>
                        <div className="propuesta-datos-contenido">
                            <h3>LÍNEAS DE ACCIÓN</h3>
                            <ul>
                                <li>Promover alianzas con el sector privado para construir o desarrollar naves industriales listas para la instalación de nuevos inversionistas nacionales o internacionales</li>
                                <li>Alinear el diseño de hospedaje industrial conforme a las necesidades de inversionistas nacionales o internacionales prospecto</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="propuestas-propuesta">
                    <div className="propuesta-imagen">
                        <img src={three} alt=""/>
                        <div className="propuesta-imagen-filtro"></div>
                        <div className="propuesta-indice">3</div>
                        <p className="propuesta-titulo">
                            Distrito de <span>innovación y tecnología</span>
                        </p>
                    </div>
                    <div className="propuesta-datos">
                        <div className="propuesta-datos-cabecera">
                            <h3>OBJETIVO ESPECÍFICO</h3>
                            <p>Aumentar la supervivencia de las empresas de nueva creación, así como las capacidades necesarias para tener éxito en la era de la información.</p>
                        </div>
                        <div className="propuesta-datos-contenido">
                            <h3>LÍNEAS DE ACCIÓN</h3>
                            <ul>
                                <li>Incrementar el numero de prospectos y mejorar la tasa de conversión de inversionistas de alto impacto internacionales</li>
                                <li>Organizar eventos de capital semilla donde proyectos de emprendedores puedan acceder a fondos privados de inversionistas locales</li>
                                <li>Lograr alianzas con modelos de incubación y aceleración de negocios con las instituciones más exitosas a nivel nacional e internacional</li>
                            </ul>
                        </div>
                    </div>
                </div><div className="propuestas-propuesta">
                    <div className="propuesta-imagen">
                        <img src={four} alt=""/>
                        <div className="propuesta-imagen-filtro"></div>
                        <div className="propuesta-indice">4</div>
                        <p className="propuesta-titulo">
                            <span>Créditos</span> para <span>Pymes</span>
                        </p>
                    </div>
                    <div className="propuesta-datos">
                        <div className="propuesta-datos-cabecera">
                            <h3>OBJETIVO ESPECÍFICO</h3>
                            <p>Acelerar el crecimiento de las MiPymes</p>
                        </div>
                        <div className="propuesta-datos-contenido">
                            <h3>LÍNEAS DE ACCIÓN</h3>
                            <ul>
                                <li>Facilitar el acceso al crédito a las Micro, Pequeñas y Medianas empresas del municipio para capital de trabajo y adquisición de activos fijos</li>
                                <li>Impulsar esquemas más eficiente de comercialización en el mercado y tener mayor innovación en el sector productivo</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Propuestas
