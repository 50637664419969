import React from "react";
import "./mitrabajo.scss";
import minimas from "./../../assets/img/minimas.png";

const Mitrabajo = ({ propRef }) => {
  return (
    <div className="mitrabajo-component" ref={ propRef }>
      <div className="title-component">
        <h4> MI TRABAJO</h4>
      </div>
      <div className="items-component">
        <div className="esc-made">
          <div className="title-1">
            {" "}
            <h4>
              Escuchar a<br />
              <strong> mi comunidad </strong>
            </h4>
            <img
              className="imagenmas-122"
              src={minimas}
              alt={"Torreón Coahuila Merece Más"}
            />
          </div>
          <div className="imageee-1"></div>
          <div className="pharagraph">
            <p>
              Siempre he creído que hacer es la mejor manera de decir. Busco
              conocer a mi comunidad y ser inspirado por ella, por eso trabajo
              todos los días por un mejor Coahuila, visitándote en tu colonia y
              escuchando tus necesidades y propuestas.
            </p>
          </div>
        </div>

        <div className="esc-made">
          <div className="title-1">
            {" "}
            <h4>
              Crear mejores
              <br />
              <strong>oportunidades</strong>
            </h4>
            <img
              className="imagenmas-123"
              src={minimas}
              alt={"Torreón Coahuila Merece Más"}
            />
          </div>
          <div className="imageee-12"></div>
          <div className="pharagraph">
            <p>
              Trabajo para garantizar un mejor presente y futuro para ti y tu
              familia. Mi deber como diputado es gestionar estas oportunidades,
              llevando iniciativas, puntos de acuerdo y pronunciamientos ante el
              Congreso del Estado para convertirlas en leyes que nos beneficien
              a todas y todos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mitrabajo;
