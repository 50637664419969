import React from 'react'
import './footer.scss'
import logo from './../../assets/img/logo.svg'
import facebook from './../../assets/img/facebook.svg'
import twitter from './../../assets/img/twitter.svg'
import instagram from './../../assets/img/instagram.svg'
import nombre from './../../assets/img/nombre.svg'

const Footer = () => {
    return (
        <footer>
            <img className="footer-logo" src={logo} alt="Marcelo Torres"/>
            <div className="footer-copyright">
                <p>Copyright © Todos los derechos reservados.</p>
            </div>
            <div className="footer-redes-propuestas">
                <div className="footer-propuestas">
                    <p>Conoce mis propuestas</p>
                </div>
                <div className="footer-redes">
                    <a href="https://www.facebook.com/marcelotorresc" target="blank"><img src={facebook} alt="Facebook Marcelo Torres"/></a>
                    <a href="https://twitter.com/marcelotorresc" target="blank"><img src={twitter} alt="Twitter Marcelo Torres"/></a>
                    <a href="https://www.instagram.com/marcelotorresc/" target="blank"><img src={instagram} alt="Instagram Marcelo Torres"/></a>
                    <img className="nombre" src={nombre} alt="Marcelo Torres"/>
                </div>
            </div>
        </footer>
    )
}

export default Footer
