import Video from "./components/video-section/Video";
import Trayectoria from "./components/trayectoria/Trayectoria";
import Metas from "./components/metas/Metas";
import Imagenescuadros from "./components/imagenescuadros/Imagenescuadros";
import Mitrabajo from "./components/mitrabajo/Mitrabajo";
import React, { useState, useRef, useEffect } from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import Propuestas from "./components/propuestas/Propuestas";
import Sidemenu from "./components/sidemenu/Sidemenu";
import Contacto from "./components/contacto/Contacto";
import ReactGa from "react-ga";

function App() {
  useEffect(() => {
    ReactGa.initialize("UA-158569930-1");
    ReactGa.pageview("marcelotorres.mx");
  }, []);

  const trayectoriaRef = useRef(null);
  const propuestasRef = useRef(null);
  const contactoRef = useRef(null);
  const trabajoRef = useRef(null);
  const executeScroll = (ref) => {
    switch (ref) {
      case "trayectoria":
        window.scrollTo({
          top: trayectoriaRef.current.offsetTop - 100,
          behavior: "smooth",
        });
        break;
      case "propuestas":
        window.scrollTo({
          top: propuestasRef.current.offsetTop - 100,
          behavior: "smooth",
        });
        break;
      case "contacto":
        window.scrollTo({
          top: contactoRef.current.offsetTop - 30,
          behavior: "smooth",
        });
        break;
      case "trabajo":
        window.scrollTo({
          top: trabajoRef.current.offsetTop - 30,
          behavior: "smooth",
        });
        break;
      default:
        break;
    }
  };

  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Navbar toggle={toggle} scroll={executeScroll} />
      <Sidemenu visible={visible} toggle={toggle} scroll={executeScroll} />
      <Header />
      <Video />
      <Trayectoria propRef={trayectoriaRef} />
      <Propuestas propRef={propuestasRef} />
      <Metas />
      <Imagenescuadros />
      <Contacto propRef={contactoRef} />
      <Mitrabajo propRef={trabajoRef} />
      <Footer />
    </>
  );
}

export default App;
