import React from 'react'
import './header.scss'
import Marcelo from './../../assets/img/logo.svg'
import Merece from './../../assets/img/merece.svg'
import Vota from './../../assets/img/vota pan.svg'

const Header = () => {
    return (
        <div className="header-container">
            <div>
                <img src={Marcelo} alt="Marcelo Torres"/>
                <img src={Merece} alt="Torreón merece más"/>
                <img src={Vota} alt="Vota Pan"/>
            </div>
            <div className="marcelo-torres"></div>
        </div>
    )
}

export default Header
