import React from "react";
import "./metas.scss";
import signmarcelo from "./../../assets/img/signature.svg";
import arrowvector from "../../assets/img/arrowvector.svg";
import torreonmerecemas from "../../assets/img/torreonmerecemas.png";
import marcelopresidente from "../../assets/img/marcelopresidente.png";
import votapan from "../../assets/img/votapan.png";
import download from "downloadjs";

const Metas = () => {
  // The React.JS code for the download method:

  return (
    <div className="metas-section">
      <div className="metas-container">
        <div className="first-content">
          <h4>
            Tengo una meta clara: hacer el mayor crecimiento histórico de
            Torreón, nunca antes visto. <br />
            <strong>Torreón Merece Más.</strong>{" "}
          </h4>
          <img
            className="Marcelo-trc-11"
            src={signmarcelo}
            alt={"Marcelo Torres Presidente Municipal"}
          />
        </div>
        <div className="second-content">
          <div className="logotio-section">
            <img
              className="Marcelo-trc-111 imagesss"
              src={torreonmerecemas}
              alt={"Torreón Coahuila Merece Más"}
            />
          </div>
          <div className="logotio-section ocult-child">
            <img
              onClick={() => download(torreonmerecemas, "Torreon Merece Mas")}
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>

          <div className="logotio-section">
            <img
              className="Marcelo-trc-112 imagesss"
              src={marcelopresidente}
              alt={"Marcelo Torres Presidente Municipal"}
            />
          </div>

          <div className="logotio-section ocult-child">
            <img
              onClick={() =>
                download(
                  marcelopresidente,
                  "Marcelo Torres Presidente Municipal.png"
                )
              }
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>
          <div className="logotio-section">
            <img
              className="Marcelo-trc-113 imagesss"
              src={votapan}
              alt={"Marcelo Torres Presidente Municipal"}
            />
          </div>
          <div className="logotio-section ocult-child">
            {" "}
            <img
              onClick={() => download(votapan, "Vota Pan.png")}
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>
          <div className="logotio-section ocult-big">
            <img
              onClick={() => download(torreonmerecemas, "Torreon Merece Mas")}
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>
          <div className="logotio-section ocult-big">
            <img
              onClick={() =>
                download(
                  marcelopresidente,
                  "Marcelo Torres Presidente Municipal.png"
                )
              }
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>
          <div className="logotio-section ocult-big">
            {" "}
            <img
              onClick={() => download(votapan, "Vota Pan.png")}
              className="arrow-down-section"
              src={arrowvector}
              alt={"Descarga"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metas;
