import React from 'react'
import logo from './../../assets/img/logo.svg'
import './navbar.scss'

const Navbar = ({ toggle, scroll }) => {
    const hamburguerHandler = (e) => {
        document.getElementById('nav-icon3').classList.toggle('open')
        toggle()
    }
    return (
        <nav>
            <div>
                <div className="img">
                    <img src={logo} alt="Marcelo Torres"/>
                </div>
                <ul>
                    <li className="link" onClick={() => scroll("trayectoria")}><span data-content="Mi trayectoria">Mi trayectoria</span></li>
                    <li className="link" onClick={() => scroll("propuestas")}><span data-content="Propuestas de campaña">Propuestas de campaña</span></li>
                    <li className="link" onClick={() => scroll("contacto")}><span data-content="Contacto">Contacto</span></li>
                    <li className="link" onClick={() => scroll("trabajo")}><span data-content="Mi trabajo">Mi trabajo</span></li>
                </ul>
                <div id="nav-icon3" onClick={hamburguerHandler}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
