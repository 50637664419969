import React from "react";
import "./video.scss";
import logotipovideo from "../../assets/img/logotipovideo.png";

const Video = () => {
  return (
    <div className="video-container">
      <div className="hw-2">
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/vcvwmSy1_BU?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="text-1">
          <img
            classname="img-marcelo"
            src={logotipovideo}
            alt={"Marcelo Torres Presidente Municipal"}
          />
          <h1 className="text-2">
            Orgullosamente de Torreón. Padre de familia y guerrero de corazón.
            Seguro que Torreón Merece Más y va a tener Más.{" "}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Video;
