import React from 'react'
import './sidemenu.scss'

const Sidemenu = ({ visible, toggle, scroll }) => {  

    const scrollM = (ref) => {
        scroll(ref)
        toggle()
    }

    return (
        <>
            <div className={`side-menu ${visible? 'visible' : ''}`}>
                <ul>
                    <li className="link" onClick={() => scrollM("trayectoria")}><span data-content="Mi trayectoria">Mi trayectoria</span></li>
                    <li className="link" onClick={() => scrollM("propuestas")}><span data-content="Propuestas de campaña">Propuestas de campaña</span></li>
                    <li className="link" onClick={() => scrollM("contacto")}><span data-content="Contacto">Contacto</span></li>
                    <li className="link" onClick={() => scrollM("trabajo")}><span data-content="Mi trabajo">Mi trabajo</span></li>
                </ul>
            </div>
            <div className={`overlay ${visible? 'visible' : ''}`}></div>
        </>
    )
}

export default Sidemenu
