import React from "react";
import "./imagenescuadros.scss";

const Imagenescuadros = () => {
  return (
    <div className="images-container">
      <div className="uno"></div>
      <div className="dos"></div>
      <div className="tres"></div>
      <div className="cuatro"></div>
      <div className="cinco"></div>
      <div className="seis"></div>
      <div className="siete"></div>
      <div className="ocho"></div>
    </div>
  );
};

export default Imagenescuadros;
